import { handlerFiltersPutBrand } from '../../utils'
import Analyzer from '../analyzer'

export default class CustomZoneAnalyzer extends Analyzer {
    customZoneResults: CustomZoneResult[]

    constructor(rallyId: number, subRallyId : number, section: Section, excluded: any, connections: DevicesConnections, routesReports: any) {
        super(rallyId,subRallyId, section, excluded, connections, routesReports)
        this.customZoneResults = []
    }
    public createWorkers(participants: Participant[], maxWorkers: number) {
        super.createWorkers(participants, maxWorkers, 'custom')
    }
    fetchResults = async (
        participants: Participant[],
        data: DataRallyForm,
        token: string,
        trackCaches: TrackCaches,
        brandFilters: any,
        modeSandBox : boolean
    ) => {
        const specificParams = this._getRequestParamsSpecific(data)
        let brands = null
        if (!modeSandBox) {
            brands = await handlerFiltersPutBrand(
                this.subrallyId,
                this.section.section,
                token,
                data.detectionType,
                specificParams,
                brandFilters
            )
        }
        if (brands) return brands

        for (let participant of participants) {
            const caches = trackCaches[participant.id]
            const params = this._getRequestParams(
                this.rallyId,
                this.subrallyId,
                this.section,
                participant,
                data,
                token,
                caches,
                this.connections
            )
            const worker = await this._pool.get()
            if (worker == null) {
                break
            }

            worker.onmessage = async (e: MessageEvent<MessageAlgorithmCustomZone>) => {
                const messageType: string = e.data.type
                switch (messageType) {
                    case 'cacheUpdate':
                        this.updateCache(e)
                        return
                    case 'error':
                        this.updateError(e)
                        return
                    case 'customZoneResult':
                        const generalParamsForReport = e.data.generalParamsForReport
                        const resultParticipant = e.data.participant
                        let resultInfo: CustomZoneReport = e.data.result
                        const disconnectedGpsTrack = generalParamsForReport.disconnectedGpsTrack
                        const result: CustomZoneResult = {
                            highlightedPoints: resultInfo.highlightedPoints,
                            detectionType: data.detectionType,
                            id: resultParticipant.id,
                            number: resultParticipant.numero,
                            data: resultInfo,
                            sectionId: this.section.section,
                            rally: this.subrallyId,
                            token: token,
                            participantTracks: generalParamsForReport.participantTracks || [],
                            connections: generalParamsForReport.connections,
                            areaPoints: resultInfo.areaPoints,
                            customTracks: [],
                            disconnectedGpsTrack: disconnectedGpsTrack,
                        }
                        this.customZoneResults = [...this.customZoneResults, result]
                        this.onResults(this.customZoneResults)
                        break
                    default:
                        return
                }
                this._pool.put(worker)
            }

            worker.postMessage(['customZoneProcess', params])
        }

        while (this._pool.activeWorkers.length > 0) {
            await new Promise(r => setTimeout(r, 0))
        }

        this._pool.free()
    }

    getResults = () => this.customZoneResults
    _getRequestParamsSpecific = (data: DataRallyForm) => {
        
        return {
            consecSecs: Number(data.detectionTypeData.consecSecs),
            entryBearing: isNaN(data.detectionTypeData.entryBearing) ? null : data.detectionTypeData.entryBearing,
            entryCoords : data.detectionTypeData.entryCoords,
            exitCoords : data.detectionTypeData.exitCoords,
            entryThreshold: Number(data.detectionTypeData.entryThreshold),
            exitBearing: isNaN(data.detectionTypeData.exitBearing) ? null : data.detectionTypeData.exitBearing,
            exitThreshold: Number(data.detectionTypeData.exitThreshold),
            maxSpeed: data.detectionTypeData.maxSpeed,
            minSpeed: data.detectionTypeData.minSpeed,
            minTimeInZone: data.detectionTypeData.minTimeInZone,
            toleranceKmH: Number(data.detectionTypeData.toleranceKmH),
        }
    }
    _getRequestParams = (
        rallyId: number,
        subrallyId : number,
        section: Section,
        participant: Participant,
        data: DataRallyForm,
        token: string,
        caches: TrackCache,
        connections: DevicesConnections
    ): ParamsAnalyzerCustomZone => {
        const entryCoords = data.detectionTypeData?.entryCoords
        const exitCoords = data.detectionTypeData?.exitCoords

        console.log(entryCoords)
        console.log(exitCoords)
        const reqData: ParamsAnalyzerCustomZone = {
            token: token,
            subrallyId: subrallyId,
            rallyId : rallyId,
            section: section,
            from: data.from,
            to: data.to,
            participantTrackType: data.participantTrackType,
            participantUINumber: participant.numero,
            detectionType: data.detectionType,
            caches: caches,
            connections,
            participant: participant,
            threshold: data.detectionTypeData.threshold,
            consecSecs: Number(data.detectionTypeData.consecSecs),
            entryBearing: isNaN(data.detectionTypeData.entryBearing) ? null : data.detectionTypeData.entryBearing,
            entryLatitude: Number(entryCoords[0]),
            entryLongitude: Number(entryCoords[1]),
            entryThreshold: Number(data.detectionTypeData.entryThreshold),
            exitBearing: isNaN(data.detectionTypeData.exitBearing) ? null : data.detectionTypeData.exitBearing,
            exitLatitude: Number(exitCoords[0]),
            exitLongitude: Number(exitCoords[1]),
            exitThreshold: Number(data.detectionTypeData.exitThreshold),
            lockForm: data.detectionTypeData.lockForm,
            maxSpeed: Number(data.detectionTypeData.maxSpeed),
            minSpeed: Number(data.detectionTypeData.minSpeed),
            minTimeInZone: data.detectionTypeData.minTimeInZone,
            toleranceKmH: Number(data.detectionTypeData.toleranceKmH),
        }

        return reqData
    }
}
