import { Input } from 'antd'
import { isArray } from 'lodash'
import { useEffect, useState } from 'react'

/**
 * @param defaultValue Default Value
 * @param x Remaining props that take the component from the parent component
 */
interface PropsInputCoordinates {
    defaultValue: any
    valueForm: any
    [x: string]: any
}

/**
 * Custom input for the use of coordinates
 * @param {PropsInputCoordinates} object Object to contruct the component
 * @returns
 */
const CustomInputCoordinates = ({ defaultValue, valueForm, ...restProps }: PropsInputCoordinates) => {
    const [value, setValue] = useState(valueForm)

    useEffect(() => {
        if (valueForm) {
            setValue(valueForm)
        }
    }, [valueForm])

    useEffect(()=>{
        const coord = value.split(',')
        if (coord.length === 2) {
            if (coord[0] && coord[1] && !isNaN(Number(coord[0].trim())) && !isNaN(Number(coord[1].trim()))) {
                restProps.onChange([Number(coord[0].trim()), Number(coord[1].trim())])
            }
        }
    },[value])
    return (
        <Input
            className="w-full"
            size="small"
            value={value}
            {...restProps}
            onChange={event => {
                setValue(event.target.value)
            }}
        />
    )
}
export default CustomInputCoordinates
